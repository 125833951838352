@import "../settings";

$marker-pin-height: rem-calc(30);
$marker-pin-width: rem-calc(30);

.block--image-hotspot {
	position: relative;

	img {
		object-fit: cover;
		width: 100%;
	}


  .mon-modal_dialog {
    -webkit-mask-image: url('../images/product-card-modal.svg');
    mask-image: url('../images/product-card-modal.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
    background-color: $white;
    mask-origin: fill-box;
    max-width: 340px !important;
    position: relative;

    @include breakpoint(medium) {
      max-width: 386px !important;
    }

    .card {
      align-items: flex-start;
      text-align: left;

      img {
        margin-bottom: 0;
      }

      .button {
        margin-bottom: 0;
      }

      &:hover {
        transform: none;
      }

      .card__category {
        margin-bottom: 0;
      }

      .card__title {
        font-size: 28px;
        margin-bottom: 0;
      }

      .card__price {
        font-size: 21px;
        margin-bottom: $global-margin / 2;
      }
    }
  }

  // On large breakpoint, if viewport height is less that 800px set top value to 200px
  @include breakpoint(large) {
    @media screen and (max-height: 800px) {
      .mon-modal_dialog {
        // position: fixed !important;
        top: 510px !important;
      }
    }
  }
}

.marker-pin {
	height: $marker-pin-height;
	margin-left: -$marker-pin-height / 2;
	margin-top: -$marker-pin-height / 2;
	position: absolute;
	width: $marker-pin-width;
  transition: all .3s ease-in-out;

	@include breakpoint(large) {
		height: $marker-pin-height * 2.5;
		margin-left: - $marker-pin-height;
		margin-top: - $marker-pin-height;
		width: $marker-pin-width * 2.5;
	}

  &:hover {
    transform: scale(1.05);
  }

}
